import React from 'react';
import './App.css';
import firebase from './firebase';
import { useBeforeunload } from 'react-beforeunload';
import { useHistory } from 'react-router-dom';
import { Prompt } from 'react-router';

import * as ROUTES from './routes';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';

import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// import Autocomplete from '@material-ui/lab/Autocomplete';
// import Autocomplete from 'react-autocomplete';
// import { getEMNames } from 'looker-consulting-shared/engagementManagers';

import TopicList from './TopicList';
import { getNowHourResolution, convertClientDataFromFirebase, addCodevNumbersToMeetings } from 'looker-consulting-shared/utils';
import { MEETING_TYPES, MEETING_TYPE_DETAILS } from 'looker-consulting-shared/meetingTypes';
import { ENGAGEMENT_STATUS_KEYS, ENGAGEMENT_STATUSES } from 'looker-consulting-shared/engagementStatuses';
import { ENGAGEMENT_TYPE_KEYS, ENGAGEMENT_TYPES } from 'looker-consulting-shared/engagementTypes';
import { POCS_INVOLVEMENTS } from 'looker-consulting-shared/pocs';


const DEFAULT_MEETING_FIELDS = {datetime: getNowHourResolution(), meetingType: MEETING_TYPES.CODEV, prep: '', summary: ''};
const DEFAULT_POCS_FIELDS = {name: '', email: '', role: '', involvement: POCS_INVOLVEMENTS.MAIN};

function EditClientPage({ match, user }) {
  let history = useHistory();

  const [loading, setLoading] = React.useState(true);
  const [clientData, setClientData] = React.useState({
    name: '',
    engagementType: ENGAGEMENT_TYPE_KEYS.JUMPSTART,
    engagementStatus: ENGAGEMENT_STATUS_KEYS.ACTIVE,
    engagementManager: '',
    location: '',
    website: '',
    imageUrl: '',
    notes: '',
    pocs: [],
    selectedTopics: [],
    meetings: []//[{...DEFAULT_MEETING_FIELDS}],
  });

  const [fetchError, setFetchError] = React.useState();
  const [formDirty, setFormDirty] = React.useState(false);

  const isAddClient = () => {
    return !match || !match.params || !match.params.id
  }

  useBeforeunload((event) => {
    if (formDirty) {
      event.preventDefault();
    }
  });

  React.useEffect(() => {
    const fetchData = async () => {
      const db = firebase.firestore();

      // Coming from the Add Client page so no initization
      if (isAddClient()) {
        setLoading(false);
        return;
      }

      const clientId = match.params.id;
      console.log('Fetching Client ID: ', clientId);

      try {
        const doc = await db.collection('clients').doc(clientId).get();
        if (doc.exists) {
          const docData = doc.data();

          // Override existing client data with what's in the db
          setClientData({
            ...clientData,
            ...convertClientDataFromFirebase(docData, doc.id)
          });
        } else {
          console.log("No such document!");
          setFetchError('Error no client with id=' + clientId)
        }
      } catch(e) {
        console.log('Error', e);
        setFetchError('A fetch error occurred or you do not have permissions to access the client with id=' + clientId);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSetClientDataField = (fieldName) => ((event) => {
    setFormDirty(true);
    setClientData({
      ...clientData,
      [fieldName]: event.target.value
    });
  });

  const handleCancel = () => {
    if (isAddClient()) {
      history.push(ROUTES.MAIN);
    } else {
      history.push(ROUTES.VIEW_CLIENT + '/' + match.params.id);
    }
  }

  const handleTopicChange = (clickedTopicId) => ((event) => {
    setFormDirty(true);
    let selectedTopics;

    if (event.target.checked) {
      // Add in topicId to selectedTopics
      selectedTopics = [
        ...clientData.selectedTopics,
        clickedTopicId
      ];
    } else {
      // Remove the clicked topicId
      selectedTopics = clientData.selectedTopics.filter((topicId) => {
        return clickedTopicId !== topicId;
      });
    }

    setClientData({
      ...clientData,
      selectedTopics: selectedTopics
    })
  });

  const saveForm = () => {
    if (clientData.name === '') {
      alert('Need a client name');
    } else {
      const db = firebase.firestore();

      // Add the codiv numbers to all meetings which are codivs
      const annotatedClientData = {
        // Make sure the meetings have numbers if codevs
        ...addCodevNumbersToMeetings(clientData),

        // Make sure the ownerId is set
        ownerId: user.uid
      };
      delete annotatedClientData.startDate;
      delete annotatedClientData.endDate;

      if (isAddClient()) {
        db.collection('clients').add(annotatedClientData)
        .then(ref => {
          // The form is saved so no longer dirty
          setFormDirty(false);

          console.log('Added document with ID: ', ref.id);
          history.push(ROUTES.VIEW_CLIENT + '/' + ref.id);
        })
        .catch(e => {
          console.log('Error adding a new client', e);
        });
      } else {
        const docId = match.params.id;
        db.collection('clients').doc(match.params.id).set(annotatedClientData)
        .then(() => {
          // The form is saved so no longer dirty
          setFormDirty(false);

          console.log('Updated document with ID: ', docId);
          history.push(ROUTES.VIEW_CLIENT + '/' + docId);
        })
        .catch((e) => {
          console.log('Error updating a new client', e);
        });;
      }
    }
  }

  const handleSetPocField = (pocsField, i) => ((event) => {
    setFormDirty(true);

    // Clone meetings
    const clonedPocs = clientData.pocs.map((poc) => {
      return { ...poc }
    });

    // Update the value
    const newValue = event.target.value;
    clonedPocs[i][pocsField] = newValue;

    setClientData({
      ...clientData,
      pocs: clonedPocs
    });
  })

  const handleAddPocs = () => {
    setFormDirty(true);

    setClientData({
      ...clientData,
      pocs: [
        ...clientData.pocs,
        {...DEFAULT_POCS_FIELDS}
      ]
    });
  }

  const renderPocs = () => {
    return (
        clientData.pocs.map((poc, i) => (
          <Grid container key={i}>
            <Grid item sm={3} xs={12}>
              <TextField
                label="Name"
                value={poc.name}
                onChange={handleSetPocField('name', i)}
                style={{width: '80%', margin: '5px'}}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                label="Email"
                value={poc.email}
                onChange={handleSetPocField('email', i)}
                style={{width: '80%', margin: '5px'}}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                label="Role"
                value={poc.role}
                onChange={handleSetPocField('role', i)}
                style={{width: '80%', margin: '5px'}}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Involvement</FormLabel>
                <RadioGroup aria-label="involvement" name="involvement" value={poc.involvement} onChange={handleSetPocField('involvement', i)} row>
                  <FormControlLabel
                    value={POCS_INVOLVEMENTS.MAIN}
                    control={<Radio color="primary" />}
                    label="Main"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={POCS_INVOLVEMENTS.CC}
                    control={<Radio color="primary" />}
                    label="CC"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        ))
    );
  }


  //
  // Meetings
  //

  const handleSetMeetingField = (meetingField, i) => ((event) => {
    setFormDirty(true);

    // Clone meetings
    const clonedMeetings = clientData.meetings.map((meeting) => {
      return { ...meeting }
    });

    // Update the value
    let newValue;
    if (meetingField === 'datetime') {
      newValue = event;
    } else {
      newValue = event.target.value;
    }

    clonedMeetings[i][meetingField] = newValue;

    setClientData({
      ...clientData,
      meetings: clonedMeetings
    });
  })

  const handleAddMeeting = () => {
    setFormDirty(true);

    setClientData({
      ...clientData,
      meetings: [
        ...clientData.meetings,
        {...DEFAULT_MEETING_FIELDS}
      ]
    });
  }

  const renderMeetings = () => {
    return (
        clientData.meetings.map((meeting, i) => (
          <Grid container key={i}>
            <Grid item sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  variant="inline"
                  ampm={false}
                  label="Meeting Time"
                  value={meeting.datetime}
                  onChange={handleSetMeetingField('datetime', i)}
                  onError={console.log}
                  format="MM/dd/yyyy HH:mm"
                  style={{width: '80%', margin: '5px'}}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl style={{width: '80%', margin: '5px'}}>
                <InputLabel>Meeting Type</InputLabel>
                <Select
                  value={meeting.meetingType}
                  onChange={handleSetMeetingField('meetingType', i)}
                >
                  {MEETING_TYPE_DETAILS.map((mt, idx) => (
                    <MenuItem key={mt.id} value={mt.id}>{mt.display}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Prep"
                variant="outlined"
                multiline
                rows={meeting.prep === '' ? 1 : 4}
                value={meeting.prep}
                onChange={handleSetMeetingField('prep', i)}
                style={{width: '80%', margin: '5px'}}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Summary"
                variant="outlined"
                multiline
                rows={meeting.summary === '' ? 1 : 4}
                value={meeting.summary}
                onChange={handleSetMeetingField('summary', i)}
                style={{width: '80%', margin: '5px'}}
              />
            </Grid>
          </Grid>
        ))
    );
  }


  //
  // Let's start rendering
  //

  // If loading just show the spinner
  if (loading) {
    return <CircularProgress />;
  }

  // Let the user know if there is an error
  if (fetchError) {
    return (
      <Container>
        <Typography variant="h4">An Error Occurred</Typography>
        <Typography>{fetchError}</Typography>
      </Container>
    );
  }

  // Otherwise show the page
  return (
    <form>
      <Prompt
        when={formDirty}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <Typography variant="h4" noWrap>
        {isAddClient() ? 'Add Client' : 'Update Client'}
      </Typography>

      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <TextField
            id="standard-basic"
            label="Client Name"
            value={clientData.name}
            onChange={handleSetClientDataField('name')}
            style={{width: '80%'}}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <FormControl style={{width: '80%', margin: '5px'}}>
            <InputLabel>Engagement Status</InputLabel>
            <Select
              value={clientData.engagementStatus}
              onChange={handleSetClientDataField('engagementStatus')}
            >
              {Object.keys(ENGAGEMENT_STATUSES).map((esKey) => (
                <MenuItem key={esKey} value={esKey}>{ENGAGEMENT_STATUSES[esKey]}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={3} xs={12}>
          {/* <Autocomplete
            freeSolo
            disableClearable
            options={getEMNames()}
            value={clientData.engagementManager}
            onChange={handleSetClientDataField('engagementManager')}
            renderInput={params => (
              <TextField
                {...params}
                label="Engagement Manager"
                margin="normal"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                  onChange: (e) => {
                    onTagInputChange(e, e.target.value || null);
                    props.InputProps.onChange(e);
                  },
                }}
                style={{width: '80%'}}
              />
            )}
          /> */}
          <TextField
            id="standard-basic"
            label="Engagement Manager"
            value={clientData.engagementManager}
            onChange={handleSetClientDataField('engagementManager')}
            style={{width: '80%'}}
          />
          {/* <Autocomplete
            getItemValue={(item) => item}
            items={getEMNames()}
            renderItem={(item, isHighlighted) =>
              <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                {item}
              </div>
            }
            value={clientData.engagementManager}
            onChange={handleSetClientDataField('engagementManager')}
            onSelect={handleSetClientDataField('engagementManager')}
          /> */}
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            id="standard-basic"
            label="Location"
            value={clientData.location}
            onChange={handleSetClientDataField('location')}
            style={{width: '80%'}}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            id="standard-basic"
            label="Website"
            value={clientData.website}
            onChange={handleSetClientDataField('website')}
            style={{width: '80%'}}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Notes"
            variant="outlined"
            multiline
            rows="4"
            value={clientData.notes}
            style={{width: '80%'}}
            onChange={handleSetClientDataField('notes')}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
        <FormControl style={{width: '80%', margin: '5px'}}>
            <InputLabel>Engagement Type</InputLabel>
            <Select
              value={clientData.engagementType}
              onChange={handleSetClientDataField('engagementType')}
            >
              {Object.keys(ENGAGEMENT_TYPES).map((etKey) => (
                <MenuItem key={etKey} value={etKey}>{ENGAGEMENT_TYPES[etKey]}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <FormControl component="fieldset">
            <FormLabel component="legend">Engagement Type</FormLabel>
            <RadioGroup aria-label="position" name="position" value={clientData.engagementType} onChange={handleSetClientDataField('engagementType')} row>
              <FormControlLabel
                value="js"
                control={<Radio color="primary" />}
                label="Jumpstart"
                labelPlacement="end"
              />
              <FormControlLabel
                value="rd"
                control={<Radio color="primary" />}
                label="Rapid Deployment"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl> */}
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            id="standard-basic"
            label="Image URL"
            value={clientData.imageUrl}
            onChange={handleSetClientDataField('imageUrl')}
            style={{width: '80%'}}
          />
        </Grid>
      </Grid>
      <Typography variant="h4" noWrap>Points Of Contact</Typography>
      {renderPocs()}
      <Button variant="contained" color="primary" onClick={handleAddPocs}>
        Add Point Of Contact
      </Button>

      <Typography variant="h4" noWrap>Meetings</Typography>
      {renderMeetings()}
      <Button variant="contained" color="primary" onClick={handleAddMeeting}>
        Add Meeting
      </Button>
      <Typography variant="h4" noWrap>
        Topics
      </Typography>
      <Container>
        <TopicList selectedTopics={clientData.selectedTopics} handleTopicChange={handleTopicChange} />
        <Button variant="contained" color="primary" onClick={handleCancel}>
          Cancel
        </Button>

        <Button variant="contained" color="primary" onClick={saveForm}>
          Save
        </Button>
      </Container>
    </form>
  );
}

export default EditClientPage;
