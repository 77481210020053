import React from 'react';
import Grid from '@material-ui/core/Grid';

import InfoIcon from '@material-ui/icons/Info';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
// import Tooltip from '@material-ui/core/Tooltip';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import TopicDialog from './TopicDialog';
import { TOPICS } from 'looker-consulting-shared/topics';

export default function TopicList({ selectedTopics, handleTopicChange, disabled }) {
  const [topicDialogOpen, setTopicDialogOpen] = React.useState(false);
  const [topicDialogTopic, setTopicDialogTopic] = React.useState(null);

  const handleTopicDialogOpen = (topic) => (() => {
    setTopicDialogTopic(topic);
    setTopicDialogOpen(true);
  });

  const handleTopicDialogClose = () => {
    setTopicDialogOpen(false);
  };

  const resortedTopics = []
  const jumpSize = Math.ceil(TOPICS.length / 3);
  for (let i = 0; i < jumpSize; i += 1) {
    for (let j = 0; j < 3; j += 1) {
      const idx = i + (j*jumpSize);
      if (idx < TOPICS.length) {
        console.log(idx);
        resortedTopics.push(TOPICS[idx])
      }
    }
  }

  return (
    <FormGroup>
      <Grid container spacing={2} >
        {
          resortedTopics.map((topic) => {
            let labelDiv = `${topic.name} (${topic.codev})`;
            if (disabled) {
              labelDiv = (
                <div onClick={handleTopicDialogOpen(topic)}>
                  {labelDiv}
                </div>
              );
            }

            return (
              <Grid key={'topicGrid-' + topic.id} item sm={4} xs={4} style={{textAlign: 'left'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      icon={<CheckBoxOutlineBlank />}
                      checkedIcon={<CheckBoxIcon />}
                      value={topic.id}
                      key={topic.id}
                      onChange={handleTopicChange ? handleTopicChange(topic.id) : null}
                      checked={selectedTopics.includes(topic.id)}
                    />
                  }
                  label={labelDiv}
                />
                {/* <Tooltip title={topic.details} arrow> */}
                {disabled ? null : <InfoIcon onClick={handleTopicDialogOpen(topic)} fontSize="small" style={{color: 'gray'}}/>}
                {/* </Tooltip> */}
              </Grid>
            )
          })
        }
      </Grid>
      <TopicDialog open={topicDialogOpen} handleClose={handleTopicDialogClose} topic={topicDialogTopic} />
    </FormGroup>
  );
}
