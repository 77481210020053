import React from "react";
import "./App.css";
import firebase from "./firebase";
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';


import MeetingList from './MeetingList';
import { Link } from 'react-router-dom';
import * as ROUTES from './routes';
import { convertClientDataFromFirebase } from 'looker-consulting-shared/utils';
import { ENGAGEMENT_STATUS_KEYS } from 'looker-consulting-shared/engagementStatuses';


function MainPage({ user }) {
  let history = useHistory();

  const [fetchError, setFetchError] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [clients, setClients] = React.useState([]);
  const [activeClientsOnly, setActiveClientsOnly] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      const db = firebase.firestore();

      try {
        const data = await db.collection('clients').where('ownerId', '==', user.uid).get();
        setClients(data.docs.map(doc => ({ ...convertClientDataFromFirebase(doc.data()), id: doc.id })));
      } catch(e) {
        console.log('Error', e);
        setFetchError('ERROR: Couldn\'t access server data.');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleToggleActiveOnly = () => {
    setActiveClientsOnly(!activeClientsOnly);
  }

  const getUpcomingMeetings = () => {
    let upcomingMeetings = [];
    const today = new Date();

    // Get list of upcoming meetings
    clients.forEach((client) => {
      client.meetings.forEach((meeting) => {

        // console.log('today', today);
        // console.log('meeting.datetime', meeting.datetime);

        if (meeting.datetime >= today) {
          upcomingMeetings.push({
            ...meeting,
            clientData: client,
          });
        }
      });
    })

    // Sort the meetings by date
    upcomingMeetings = upcomingMeetings.sort((meeting1, meeting2) => {
      return meeting1.datetime - meeting2.datetime;
    });

    return upcomingMeetings;
  }


  //
  // Let's start rendering
  //

  // If loading just show the spinner
  if (loading) {
    return <CircularProgress />;
  }

  // Let the user know if there is an error
  if (fetchError) {
    return (
      <Container>
        <Typography variant="h4">An Error Occurred</Typography>
        <Typography>{fetchError}</Typography>
      </Container>
    );
  }

  return (
    <ul>
      <Typography variant="h5" style={{marginTop: 20}}>Upcoming Meetings</Typography>
      <MeetingList meetings={getUpcomingMeetings()} displayClientName displaySummary={false}  fromEmail={user.email} />

      <Typography variant="h5" style={{marginTop: 20}}>
        <span onClick={handleToggleActiveOnly} style={{cursor: 'pointer'}}>
          {activeClientsOnly ? 'Active' : 'All'} Clients
        </span>
        <span style={{fontSize: 14}}>
          &nbsp;(<Link to={ROUTES.ADD_CLIENT}>Add</Link>)
        </span>
      </Typography>
      <Grid container spacing={2}>
      {clients.filter((client) => (
          activeClientsOnly ? client.engagementStatus === ENGAGEMENT_STATUS_KEYS.ACTIVE : true
        )).map((client) => (
          <Grid key={client.id} item sm={3} xs={12}>
            <Card variant="outlined">
              <CardActionArea onClick={() => {
                history.push(ROUTES.VIEW_CLIENT + '/' + client.id);
              }}>
              <CardMedia
                image={client.imageUrl}
                style={{ height: 100 }}
              />
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  {client.name}
                </Typography>
              </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
      ))}
      </Grid>
    </ul>
  );
}

export default MainPage;
