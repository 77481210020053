import React from "react";

import ReactMarkdown from 'react-markdown';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Moment from 'react-moment';

import { Link } from 'react-router-dom';
import * as ROUTES from './routes';

import { getMeetingReminderLink } from 'looker-consulting-shared/email';
import { DATETIME_FORMAT_STR } from './constants';
import { daysFromTodayCount, dayFromCountToStr } from 'looker-consulting-shared/utils';
import { getMeetingDisplayFromId, MEETING_TYPES } from 'looker-consulting-shared/meetingTypes';


function MeetingList({ meetings, displayClientName, displaySummary=true, fromEmail }) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date/Time</TableCell>
            <TableCell>Type</TableCell>
            {displayClientName ? <TableCell>Client</TableCell> : null}
            <TableCell>Prep</TableCell>
            {displaySummary ? <TableCell>Summary</TableCell> : null}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meetings.map((meeting, i) => {
            const daysCount = daysFromTodayCount(meeting.datetime);
            const daysCountStr = dayFromCountToStr(daysCount);

            let meetingType = getMeetingDisplayFromId(meeting.meetingType)

            if (meeting.meetingType === MEETING_TYPES.CODEV) {
              meetingType += ` (${meeting.codevNumber})`
            }

            const timeStyles = {};
            if (daysCount === 0) {
              timeStyles.color = 'green';
            } else if (daysCount === 1) {
              timeStyles.color = 'orange';
            }

            return (
              <TableRow key={i}>
                <TableCell>
                  {
                  meeting.datetime
                  ?
                    <React.Fragment>
                      <Moment format={DATETIME_FORMAT_STR}>
                        {meeting.datetime}
                      </Moment>
                      <div style={timeStyles}>
                        ({daysCountStr})
                      </div>
                    </React.Fragment>
                  :
                    null
                  }
                </TableCell>
                <TableCell>{meetingType}</TableCell>
                {/* TODO: Check to make sure that clientId is actually set before making this a link */}
                {displayClientName ? <TableCell><Link to={ROUTES.VIEW_CLIENT + '/' + meeting.clientData.id}>{meeting.clientData.name}</Link></TableCell> : null}
                <TableCell><ReactMarkdown>{meeting.prep}</ReactMarkdown></TableCell>
                {displaySummary ? <TableCell><ReactMarkdown>{meeting.summary}</ReactMarkdown></TableCell> : null}
                <TableCell>
                  <a
                    href={getMeetingReminderLink(meeting, fromEmail)}
                    target='_blank'
                    rel="noopener noreferrer"
                  >
                    Send Reminder
                  </a>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MeetingList;
