import React from 'react';

import { Link } from 'react-router-dom';
import * as ROUTES from './routes';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// import SearchIcon from '@material-ui/icons/Search';
// import InputBase from '@material-ui/core/InputBase';

import Avatar from '@material-ui/core/Avatar';

import HelpfulDocsDialog from './HelpfulDocsDialog';


const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
  // sectionDesktop: {
  //   display: 'flex',
  //   [theme.breakpoints.up('md')]: {
  //     display: '',
  //   },
  // },
  // sectionMobile: {
  //   display: 'flex',
  //   [theme.breakpoints.up('md')]: {
  //     display: 'none',
  //   },
  // },
}));

export default function TitleBar({ signOut, user }) {
  const classes = useStyles();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [helpfulDocsDialogOpen, setHelpfulDocsDialogOpen] = React.useState(false);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettings = () => {
    handleMenuClose();
    history.push(ROUTES.SETTINGS);
  }

  const handleSignOut = () => {
    handleMenuClose();
    signOut();
  }

  const handleHelpfulDocs = () => {
    handleMenuClose();
    setHelpfulDocsDialogOpen(true);
    TagManager.dataLayer({
      dataLayer: {
        event: 'toggleHelpfulDialog',
        userId: user.uid,
        object: 'helpfulDialog',
        action: 'open'
      }
   });
  }

  const closeHelpfulDocs = () => {
    setHelpfulDocsDialogOpen(false);
    TagManager.dataLayer({
      dataLayer: {
        event: 'toggleHelpfulDialog',
        userId: user.uid,
        object: 'helpfulDialog',
        action: 'close'
      }
   });
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleHelpfulDocs}>Helpful Docs</MenuItem>
      <MenuItem onClick={handleSettings}>Settings</MenuItem>
      <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow} style={{width: '100%'}}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            <Link to={ROUTES.MAIN} style={{color: 'white', textDecoration: 'none'}}>
              Looker Consulting
            </Link>
          </Typography>
          <div className={classes.grow} />
          {/* Search Bar <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search Clients…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div> */}
          <div className={classes.grow} />
          <Typography>{user.email}</Typography>
          <IconButton
            edge="end"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <Avatar alt={user.displayName} src={user.photoURL} />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <HelpfulDocsDialog open={helpfulDocsDialogOpen} handleClose={closeHelpfulDocs} />
    </div>
  );
}
