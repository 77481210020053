var _defineProperty = require("/Users/janderson/repos/c3/looker-consulting/node_modules/@babel/runtime/helpers/defineProperty");

var _ENGAGEMENT_TYPES;

var ENGAGEMENT_TYPE_KEYS = {
  JUMPSTART: 'js',
  RAPID_DEPLOYMENT: 'rd',
  OTHER: 'other'
};
var ENGAGEMENT_TYPES = (_ENGAGEMENT_TYPES = {}, _defineProperty(_ENGAGEMENT_TYPES, ENGAGEMENT_TYPE_KEYS.JUMPSTART, 'Jumpstart'), _defineProperty(_ENGAGEMENT_TYPES, ENGAGEMENT_TYPE_KEYS.RAPID_DEPLOYMENT, 'Rapid Deployment'), _defineProperty(_ENGAGEMENT_TYPES, ENGAGEMENT_TYPE_KEYS.OTHER, 'Other'), _ENGAGEMENT_TYPES); // const getEMEmailByName = (emName) => {
//   for (let em of ENGAGEMENT_MANAGERS) {
//     if (em.name === emName) {
//       return em.email
//     }
//   }
//   return null;
// }
// const getEMNames = () => (ENGAGEMENT_MANAGERS.map((em) => (em.name)))

module.exports = {
  ENGAGEMENT_TYPE_KEYS: ENGAGEMENT_TYPE_KEYS,
  ENGAGEMENT_TYPES: ENGAGEMENT_TYPES
};