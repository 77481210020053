var _require = require('./engagementManagers'),
    getEMEmailByName = _require.getEMEmailByName;

var _require2 = require('./meetingTypes'),
    getMeetingDisplayFromId = _require2.getMeetingDisplayFromId,
    MEETING_TYPES = _require2.MEETING_TYPES;

var _require3 = require('./pocs'),
    POCS_INVOLVEMENTS = _require3.POCS_INVOLVEMENTS;

var getEmailLink = function getEmailLink(to, cc, from, subject, body) {
  var googleAccount = '0'; // Select a specific google account if the from address is specified

  if (from) {
    googleAccount = from;
  } // TODO: change this to include the google from address instead of /u/1


  return "https://mail.google.com/mail/u/".concat(googleAccount, "/?view=cm&fs=1&tf=1&to=").concat(to, "&cc=").concat(cc, "&su=").concat(subject, "&body=").concat(encodeURI(body));
};

var getFirstName = function getFirstName(fullName) {
  return fullName.split(' ')[0];
};

var getEmQuestionLink = function getEmQuestionLink(emName, emEmail, clientName, fromEmail) {
  var cc = "";
  var subject = "Question About ".concat(clientName);
  var body = "Hi ".concat(getFirstName(emName), ",\n\nHope you are doing well.");
  return getEmailLink(emEmail, cc, fromEmail, subject, body);
};

var getEmailGreetingNameStr = function getEmailGreetingNameStr(names) {
  var toNames = '';
  names.forEach(function (name, idx) {
    if (names.length > 1 && idx === names.length - 1) {
      toNames += ' and ';
    } else if (idx !== 0) {
      toNames += ', ';
    }

    toNames += getFirstName(name);
  });
  return toNames;
};

var getMeetingReminderLink = function getMeetingReminderLink(meeting, fromEmail) {
  var toPocs = meeting.clientData.pocs.filter(function (poc) {
    return poc.involvement === POCS_INVOLVEMENTS.MAIN;
  });
  var ccPocs = meeting.clientData.pocs.filter(function (poc) {
    return poc.involvement === POCS_INVOLVEMENTS.CC;
  }); // The main points of contact

  var to = toPocs.map(function (poc) {
    return poc.email;
  }).join(','); // Only first names and include an "and," text or "clientName Team" if too long

  var toNames;

  if (toPocs.length > 3) {
    toNames = "".concat(meeting.clientData.name, " Team");
  } else {
    toNames = getEmailGreetingNameStr(toPocs.map(function (poc) {
      return poc.name;
    }));
  } // The engagement manager along with all CC points of contact


  var ccEmails = ccPocs.map(function (poc) {
    return poc.email;
  });

  if (meeting.clientData.engagementManager) {
    var emEmail = getEMEmailByName(meeting.clientData.engagementManager);

    if (emEmail) {
      ccEmails.push(emEmail);
    }
  }

  var cc = ccEmails.join(',');
  var fullMeetingType = getMeetingDisplayFromId(meeting.meetingType);

  if (meeting.meetingType === MEETING_TYPES.CODEV) {
    fullMeetingType += ' ' + meeting.codevNumber.toString();
  }

  var subject = "Looker ".concat(fullMeetingType).concat(meeting.meetingType === MEETING_TYPES.STATUS ? ' Meeting' : '', " Tomorrow"); // Decide on the message body

  var body = "Hi ".concat(toNames, ",\n\nI'm looking forward to our ").concat(getMeetingDisplayFromId(meeting.meetingType).toLowerCase()).concat(meeting.meetingType === MEETING_TYPES.STATUS ? ' meeting' : '', " tomorrow.  Please send any questions you have ahead of time.  Thanks!");
  return getEmailLink(to, cc, fromEmail, subject, body);
};

module.exports = {
  getEmailLink: getEmailLink,
  getFirstName: getFirstName,
  getEmailGreetingNameStr: getEmailGreetingNameStr,
  getEmQuestionLink: getEmQuestionLink,
  getMeetingReminderLink: getMeetingReminderLink
};