import React from "react";
import AuthorizationWrapper from './AuthorizationWrapper';

import firebase from "./firebase";

import withFirebaseAuth from 'react-with-firebase-auth';
 
const firebaseAppAuth = firebase.auth();
const providers = { 
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

function App({
  user,
  signOut,
  signInWithGoogle,
}) {
  return (
    <div className="App">
      {
        user
          ? <div style={{width: '100%'}}>
              <AuthorizationWrapper signOut={signOut} user={user} />
            </div>
          : <div>
              <button onClick={signInWithGoogle}>Sign in with Google</button>
            </div>
      }
    </div>
  );
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
