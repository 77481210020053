import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function HelpfulDocsDialog({ open, handleClose }) {
  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Helpful Docs
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Here are some helpful docs as you're working with your Looker clients:
        </Typography>
        <ul>
          <li>
            <a href="https://drive.google.com/file/d/1eTrERTwH3fFnTdyFMz8WBbFYSER0v6oW/view" target="_blank" rel="noopener noreferrer">
              Jumpstart Project Guide
            </a>
          </li>
          <li>
            <a href="https://docs.google.com/presentation/d/1qcQJbxU7rC69t9jo16kemQ5xfZpj1IqK56fDt8-wj7U" target="_blank" rel="noopener noreferrer">
              Developer Training Deck
            </a>
          </li>
          <li>
            <a href="https://docs.google.com/presentation/d/1Yf-J7Ul2ix38Oq3QM_xBBPlpaOhcreSZDVimfH9S_dM" target="_blank" rel="noopener noreferrer">
              Business User Training Deck
            </a>
          </li>
          <li>
            <a href="https://docs.google.com/presentation/d/1glDe5q8AlKoGuyagsQOTdhjhv8PjtygS4KcWxxNYPgE" target="_blank" rel="noopener noreferrer">
              Rapid Deployment Kickoff
            </a>
          </li>
          <li>
            <a href="https://drive.google.com/file/d/1b00VVIdsLg_Gs6WGxCxkY8RfdiJLn3oL" target="_blank" rel="noopener noreferrer">
              HTML and CSS in Looker
            </a>
          </li>
          <li>
            <a href="https://drive.google.com/open?id=1t4FuuaM7h7HoVuOCY_h8AQpqDHH_G54x" target="_blank" rel="noopener noreferrer">
              Looker Data Tests
            </a>
          </li>
          <li>
            <a href="https://drive.google.com/open?id=1nmxqKRZUpMCqEj0kUcctMC88TG8-Momh" target="_blank" rel="noopener noreferrer">
              Multistage Development Framework
            </a>
          </li>
          <li>
            <a href="https://drive.google.com/drive/u/1/folders/1rkLK1X970Qh9oxfwWTYUGc72pusEArn1" target="_blank" rel="noopener noreferrer">
              Example Dashboards
            </a>
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
