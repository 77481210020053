var ENGAGEMENT_MANAGERS = [{
  name: 'Isabela Sobral',
  email: 'isabela.sobral@looker.com'
}, {
  name: 'Eric Littell',
  email: 'ericlittell@google.com'
}, {
  name: 'Joe Belvedere',
  email: 'belvederej@google.com'
}, {
  name: 'Emily Taylor',
  email: 'emily.taylor@looker.com'
}, {
  name: 'Kim Urich',
  email: 'kim.urich@looker.com'
}];

var getEMEmailByName = function getEMEmailByName(emName) {
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = ENGAGEMENT_MANAGERS[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var em = _step.value;

      if (em.name === emName) {
        return em.email;
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return null;
};

var getEMNames = function getEMNames() {
  return ENGAGEMENT_MANAGERS.map(function (em) {
    return em.name;
  });
};

module.exports = {
  ENGAGEMENT_MANAGERS: ENGAGEMENT_MANAGERS,
  getEMEmailByName: getEMEmailByName,
  getEMNames: getEMNames
};