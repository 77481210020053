var _objectSpread = require("/Users/janderson/repos/c3/looker-consulting/node_modules/@babel/runtime/helpers/objectSpread2");

var _require = require('./meetingTypes'),
    MEETING_TYPES = _require.MEETING_TYPES;

var getNowHourResolution = function getNowHourResolution() {
  var now = new Date();
  now.setHours(now.getHours(), 0, 0, 0);
  return now;
};

var daysFromTodayCount = function daysFromTodayCount(incomingDate) {
  var today = new Date();
  today.setHours(0, 0, 0, 0);
  var incomingDateClone = new Date(incomingDate.getTime());
  incomingDateClone.setHours(0, 0, 0, 0);
  return Math.round((incomingDateClone - today) / (60 * 60 * 1000 * 24));
};

var daysFromTodayStr = function daysFromTodayStr(incomingDate) {
  var daysCount = daysFromTodayCount(incomingDate);
  return dayFromCountToStr(daysCount);
};

var dayFromCountToStr = function dayFromCountToStr(daysFromCount) {
  var daysStr = 'days'; // Shouldn't actually matter because each case has special text

  if (daysFromCount === 1 || daysFromCount === -1) {
    daysStr = 'day';
  }

  if (daysFromCount === 0) {
    return 'today';
  } else if (daysFromCount === 1) {
    return 'tomorrow';
  } else if (daysFromCount === -1) {
    return 'yesterday';
  } else if (daysFromCount < 0) {
    return Math.abs(daysFromCount).toString() + ' ' + daysStr + ' ago';
  } else {
    return 'in ' + daysFromCount.toString() + ' ' + daysStr;
  }
};

var convertClientDataFromFirebase = function convertClientDataFromFirebase(clientData, id) {
  if (!id) {
    console.warn('WARNING: id is not being passed to convertClientDataFromFirebase');
  }

  var meetingsWithDates = clientData.meetings.map(function (meeting) {
    return _objectSpread({}, meeting, {
      datetime: meeting.datetime ? new Date(meeting.datetime.seconds * 1000) : null
    });
  });
  return _objectSpread({}, clientData, {
    id: id,
    // Need to convert what comes back from firebase into a date
    startDate: clientData.startDate ? new Date(clientData.startDate.seconds * 1000) : null,
    endDate: clientData.endDate ? new Date(clientData.endDate.seconds * 1000) : null,
    meetings: meetingsWithDates
  });
};

var addCodevNumbersToMeetings = function addCodevNumbersToMeetings(clientData) {
  var codevCounter = 1;
  var meetingsWithCodevNumbers = clientData.meetings.map(function (meeting) {
    var newMeeting = _objectSpread({}, meeting);

    if (meeting.meetingType === MEETING_TYPES.CODEV) {
      newMeeting.codevNumber = codevCounter;
      codevCounter += 1;
    }

    return newMeeting;
  });
  return _objectSpread({}, clientData, {
    meetings: meetingsWithCodevNumbers
  });
};

module.exports = {
  getNowHourResolution: getNowHourResolution,
  daysFromTodayCount: daysFromTodayCount,
  daysFromTodayStr: daysFromTodayStr,
  dayFromCountToStr: dayFromCountToStr,
  convertClientDataFromFirebase: convertClientDataFromFirebase,
  addCodevNumbersToMeetings: addCodevNumbersToMeetings
};