import React from "react";
import MainPage from './MainPage';
import ClientPage from './ClientPage';
import EditClientPage from './EditClientPage';
import SettingsPage from './SettingsPage';

import firebase from "./firebase";

import * as ROUTES from './routes';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './Theme';
import TitleBar from './TitleBar';

import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

const updateUserRecord = async (user) => {
  // Try to get the doc record first
  let userData = null;
  const db = firebase.firestore();

  try {
    const doc = await db.collection('users').doc(user.uid).get();
    userData = doc.data();
  } catch(e) {
    console.log(`User does not exist yet or bad permissionns: ${user.uid}`, e);
  }

  const now = new Date();
  if (!userData) {
    // Initialize the userData
    userData = {
      createdAt: now,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
    }
  }

  // Update the last accessed date
  userData['lastAccessedAt'] = now;

  // Write out this user's data
  console.log('Writing out user data: ', userData)
  db.collection('users').doc(user.uid).set(userData)
  .then(() => {
    console.log('Wrote user : ', user.uid, userData);
  })
  .catch((e) => {
    console.log('Error writing user information', e);
  });
}

function AuthorizationWrapper({signOut, user}) {
  const [loading, setLoading] = React.useState(true);
  const [authorized, setAuthorized] = React.useState(false);

  React.useEffect(() => {
    const checkIfAuthorized = async () => {
      const db = firebase.firestore();

      // Try to access the data and save the result
      try {
        await db.collection('clients').where('ownerId', '==', user.uid);

        // Update the user record in the db because this user is authorized
        updateUserRecord(user);

        // await db.collection('clients').doc('8IZZTnI3D3vQFLYtMnpb').get();
        // await db.collection('clients').get();
        setAuthorized(true);
        setLoading(false);
      } catch(e) {
        console.log('Error', e);
        setLoading(false);
      }
    };

    checkIfAuthorized();
  }, []);

  let contents = <CircularProgress />;
  if (!loading) {
    if (authorized) {
      contents = (
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>
            <TitleBar signOut={signOut} user={user} />
            <Switch>
              <Route
                exact
                path={ROUTES.MAIN}
                render={(props) => <MainPage {...props} user={user} />}
              />
              <Route
                exact
                path={ROUTES.VIEW_CLIENT + '/:id'}
                render={(props) => <ClientPage {...props} user={user} />}
              />
              <Route
                exact
                path={ROUTES.ADD_CLIENT}
                render={(props) => <EditClientPage {...props} user={user} />}
              />
              <Route
                exact
                path={ROUTES.UPDATE_CLIENT + '/:id'}
                render={(props) => <EditClientPage {...props} user={user} />}
              />
              <Route
                exact
                path={ROUTES.SETTINGS}
                render={(props) => <SettingsPage {...props} user={user} />}
              />
            </Switch>
          </BrowserRouter>
        </MuiThemeProvider>
      );
    } else {
      contents = (
        <Container>
          I'm sorry, you are not authorized.  Please contact your employer for access.
        </Container>
      );
    }
  }

  return contents;
}

export default AuthorizationWrapper;
