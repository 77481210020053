var _defineProperty = require("/Users/janderson/repos/c3/looker-consulting/node_modules/@babel/runtime/helpers/defineProperty");

var _ENGAGEMENT_STATUSES;

var ENGAGEMENT_STATUS_KEYS = {
  ACTIVE: 'active',
  PAUSED: 'paused',
  CLOSED: 'closed'
};
var ENGAGEMENT_STATUSES = (_ENGAGEMENT_STATUSES = {}, _defineProperty(_ENGAGEMENT_STATUSES, ENGAGEMENT_STATUS_KEYS.ACTIVE, 'Active'), _defineProperty(_ENGAGEMENT_STATUSES, ENGAGEMENT_STATUS_KEYS.PAUSED, 'Paused'), _defineProperty(_ENGAGEMENT_STATUSES, ENGAGEMENT_STATUS_KEYS.CLOSED, 'Closed'), _ENGAGEMENT_STATUSES);
module.exports = {
  ENGAGEMENT_STATUS_KEYS: ENGAGEMENT_STATUS_KEYS,
  ENGAGEMENT_STATUSES: ENGAGEMENT_STATUSES
};