var MEETING_TYPES = {
  INTERNAL: 'internal',
  KICKOFF: 'kickoff',
  CODEV: 'codev',
  STATUS: 'status',
  BIZ_USER_TRAINING: 'biz_user_training',
  MIDPOINT: 'midpoint',
  CLOSEOUT: 'closeout'
};
var MEETING_TYPE_DETAILS = [{
  id: MEETING_TYPES.INTERNAL,
  display: 'Internal Transfer'
}, {
  id: MEETING_TYPES.KICKOFF,
  display: 'Kickoff'
}, {
  id: MEETING_TYPES.CODEV,
  display: 'Co-Dev'
}, {
  id: MEETING_TYPES.STATUS,
  display: 'Status'
}, {
  id: MEETING_TYPES.BIZ_USER_TRAINING,
  display: 'Biz User Training'
}, {
  id: MEETING_TYPES.MIDPOINT,
  display: 'Midpoint Call'
}, {
  id: MEETING_TYPES.CLOSEOUT,
  display: 'Closeout Call'
}];

var getMeetingDisplayFromId = function getMeetingDisplayFromId(meetingId) {
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = MEETING_TYPE_DETAILS[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var meetingType = _step.value;

      if (meetingType.id === meetingId) {
        return meetingType.display;
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return null;
};

module.exports = {
  MEETING_TYPES: MEETING_TYPES,
  MEETING_TYPE_DETAILS: MEETING_TYPE_DETAILS,
  getMeetingDisplayFromId: getMeetingDisplayFromId
};