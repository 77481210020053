import React from 'react';
import './App.css';
import firebase from './firebase';
import * as ROUTES from './routes';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

function SettingsPage({ match, user }) {
  let history = useHistory();

  const [loading, setLoading] = React.useState(true);
  const [userData, setUserData] = React.useState({
    timeFormat: '24hour',
    sendReminders: true
  });

  const [fetchError, setFetchError] = React.useState();

  React.useEffect(() => {
    const fetchData = async () => {
      const db = firebase.firestore();

      try {
        const doc = await db.collection('users').doc(user.uid).get();
        const docData = doc.data();

        // Override existing client data with what's in the db
        setUserData({
          ...userData,
          ...docData
        });
      } catch(e) {
        console.log('No such document for user, please refresh!');
        setFetchError('Please refresh');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSetUserDataField = (fieldName) => ((event) => {
    // setFormDirty(true);
    setUserData({
      ...userData,
      [fieldName]: event.target.value
    });
  });

  const saveForm = () => {
    const db = firebase.firestore();
    db.collection('users').doc(user.uid).set(userData)
    .then(() => {
      console.log('Updated settings for user with ID: ', user.uid);
      // history.push(ROUTES.VIEW_CLIENT + '/' + docId);
    })
    .catch((e) => {
      console.log('Error updating a user settings', e);
    });
  }

  //
  // Let's start rendering
  //

  // If loading just show the spinner
  if (loading) {
    return <CircularProgress />;
  }

  // Let the user know if there is an error
  if (fetchError) {
    return (
      <Container>
        <Typography variant="h4">An Error Occurred</Typography>
        <Typography>{fetchError}</Typography>
      </Container>
    );
  }

  // Otherwise show the page
  return (
    <form>
      <Typography variant="h4" noWrap>
        Settings
      </Typography>

      <Grid container spacing={2}>
        <Grid item sm={2} xs={12}>
          <FormControl style={{width: '80%', margin: '5px'}}>
            <InputLabel>Time Format</InputLabel>
            <Select
              value={userData.timeFormat}
              onChange={handleSetUserDataField('timeFormat')}
            >
              
              <MenuItem key="12hour" value="12hour">12-Hour Format</MenuItem>
              <MenuItem key="24hour" value="24hour">24-Hour Format</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Container>
        <Button variant="contained" color="primary" onClick={() => {history.push(ROUTES.MAIN)}}>
          Cancel
        </Button>

        <Button variant="contained" color="primary" onClick={saveForm}>
          Save
        </Button>
      </Container>
    </form>
  );
}

export default SettingsPage;
