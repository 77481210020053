import React from 'react';
import firebase from './firebase';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import ReactMarkdown from 'react-markdown';

import TopicList from './TopicList';
import MeetingList from './MeetingList';
import ConfirmationDialog from './ConfirmationDialog';

import { Link } from 'react-router-dom';
import * as ROUTES from './routes';
import { getEmQuestionLink } from 'looker-consulting-shared/email';

import { convertClientDataFromFirebase } from 'looker-consulting-shared/utils';
import { ENGAGEMENT_TYPES } from 'looker-consulting-shared/engagementTypes';
import { getEMEmailByName } from 'looker-consulting-shared/engagementManagers';
import { ENGAGEMENT_STATUSES } from 'looker-consulting-shared/engagementStatuses';

const getEngagementMangerDiv = (em, clientName, fromEmail) => {
  let emDiv = null;

  // Make sure we have an EM
  if (em) {
    emDiv = em;

    // Add an email link if available for this EM
    const emEmail = getEMEmailByName(em);
    if (emEmail) {
      emDiv = (
        <a
          href={getEmQuestionLink(em, emEmail, clientName, fromEmail)}
          target='_blank'
          rel="noopener noreferrer"
        >
          {emDiv}
        </a>
      );
    }
  }

  return emDiv;
}


const getClientMeetingsWithClientData = (client) => {
  return client.meetings.map((meeting) => ({
    ...meeting,
    clientData: client
  }));
}

function ClientPage({match, user}) {
  let history = useHistory();

  const [clientData, setClientData] = React.useState();
  const [fetchError, setFetchError] = React.useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const db = firebase.firestore();

      const clientId = match.params.id;
      console.log('Fetching Client ID: ', clientId);

      try {
        const doc = await db.collection('clients').doc(clientId).get();
        if (doc.exists) {
          const docData = doc.data();

          setClientData(convertClientDataFromFirebase(docData, doc.id));
        } else {
          console.log("No such document!");
          setFetchError('Error no client with id=' + clientId)
        }
      } catch(e) {
        console.log('Error', e);
        setFetchError('A fetch error occurred or you do not have permissions to access the client with id=' + clientId);
      }
    };
    fetchData();
  }, [match.params.id]);

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirmation = () => {
    setDeleteDialogOpen(false);

    const db = firebase.firestore();
    const clientId = match.params.id;
    db.collection('clients').doc(clientId).delete();

    history.push(ROUTES.MAIN);
  };

  if (fetchError) {
    return (
      <Container>
        <Typography variant="h4">An Error Occurred</Typography>
        <Typography>{fetchError}</Typography>
      </Container>
    );
  }

  if (!clientData) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Typography variant="h4">{clientData.name}</Typography>
      <Divider variant="middle" />
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <Typography>
            Engagement Type: {clientData.engagementType ? ENGAGEMENT_TYPES[clientData.engagementType] : null}
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography>
            Engagement Status: {clientData.engagementStatus ? ENGAGEMENT_STATUSES[clientData.engagementStatus] : null}
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography>
            Engagement Manager: {getEngagementMangerDiv(clientData.engagementManager, clientData.name, user.email)}
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography>
            Location: {clientData.location ? clientData.location : ''}
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography>
            {clientData.website ? <a href={clientData.website} target="_blank" rel="noopener noreferrer">{clientData.website}</a> : ''}
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          {clientData.notes ?
            <Container>
              <Typography>Notes:</Typography>
              <Divider variant="middle" />
              <ReactMarkdown>{clientData.notes}</ReactMarkdown>
            </Container>
          : ''}
        </Grid>
        <Grid item sm={4} xs={12}>
          {clientData.pocs ?
            <Container>
              <Typography>Points of Contacts</Typography>
              <Divider variant="middle" />
              {clientData.pocs.map((poc) => (
                <Typography style={{textAlign:'left'}} key={poc.name}>{poc.name}{poc.role ? ` - ${poc.role}` : null}</Typography>
              ))}
            </Container>
          : null}
        </Grid>
      </Grid>

      <Typography variant="h5" style={{marginTop: 20}}>Meetings</Typography>
      <MeetingList meetings={getClientMeetingsWithClientData(clientData)} fromEmail={user.email} />

      <Typography variant="h5" style={{marginTop: 20}}>Topics</Typography>
      <TopicList selectedTopics={clientData.selectedTopics} disabled />
      <Button variant="contained" color="primary" component={Link} to={ROUTES.UPDATE_CLIENT + '/' + match.params.id}>
        Edit
      </Button>
      <Button variant="outlined" color="primary" onClick={handleDeleteDialogOpen}>
        Delete
      </Button>
      <ConfirmationDialog title="Are you sure you want to delete?" body="If you delete this, you will lose all client and meeting data." open={deleteDialogOpen} handleCloseCancel={handleDeleteDialogClose} handleCloseConfirmation={handleDeleteConfirmation} />
    </Container>
  );
}

export default ClientPage;
